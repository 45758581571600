import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchGet } from "wcz-layout";
import { EmployeeDto } from "../models/EmployeeDto";
import { psPositionsUrl, psLeaderssUrl } from "../utils/BaseUrl";
import Project from "../models/Project";
import Position from "../models/Position";

const queryKey = "ps-employees";

export function useGetPsLeaders<TQueryFnData = EmployeeDto[], TError = string, TData = TQueryFnData>(projects: Project[], options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    const queriedProjects = (Array.from(new Set(projects.map(p => p.departmentId))));
    const url = psLeaderssUrl.replace("{{DEPARTMENT_LIST}}", queriedProjects.join(","));
    return useQuery([queryKey], ({ signal }) => fetchGet(url, signal), options);
}

export function useGetPsPositions<TQueryFnData = Position[], TError = string, TData = TQueryFnData>(projects: Project[], options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    const queriedProjects = (Array.from(new Set(projects.map(p => p.departmentId))));
    const url = psPositionsUrl.replace("{{DEPARTMENT_LIST}}", queriedProjects.join(","));
    return useQuery(["positions"], ({ signal }) => fetchGet(url, signal), options);
}