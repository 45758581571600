import { isDevelopment } from 'wcz-layout';

let apiUrl: string = "https://api.dev.wistron.eu/sot";
let apiAnetUrl: string = "https://api.wistron.eu/anet";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/sot";
}

// if (window.location.hostname === "localhost")
//     apiUrl = "http://localhost:8080";

const departmentsUrl = "https://api.dev.wistron.eu/ps/v1/department?search=companyCode==530&fields=departmentId,description";
const psLeaderssUrl = "https://api.wistron.eu/ps/v1/employee?search=departmentId=in=({{DEPARTMENT_LIST}});(position==*Leader*,position==*Super*,position==*Manager*)&fields=employeeId,nameTitleCase,position";
const psPositionsUrl = "https://api.wistron.eu/ps/v1/employee?search=departmentId=in=({{DEPARTMENT_LIST}});position!=*Leader*;position!=*Supervisor*;position!=*Manager*&fields=position";
const publicHolidayUrl = "https://api.wistron.eu/public-holiday/v1";

//export microservices base URL
export { apiUrl, apiAnetUrl, departmentsUrl, psLeaderssUrl, psPositionsUrl, publicHolidayUrl };