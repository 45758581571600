import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { ProjectSettingsContext } from "../../contexts/ProjectSettingsContext";
import { EmployeeDto } from "../../models/EmployeeDto";
import LineLeader from "../../models/LineLeader";
import Project from "../../models/Project";
import ProjectLine from "../../models/ProjectLine";
import { useDeleteLineLeader, useUpdateProject } from "../../queries/ProjectSettingsQueries";
import { useGetPsLeaders } from "../../queries/PsEmployeeQueries";

interface EditLeaderDialogProps {
    readonly leaderId: string;
    readonly setLeaderId: (id: string) => void,
    readonly line: ProjectLine,
}

export default function EditLeaderDialog(props: EditLeaderDialogProps) {
    const { leaderId, setLeaderId, line } = props;

    const { projectList } = useContext(ProjectSettingsContext);

    const { data: employeeList = [] } = useGetPsLeaders(projectList, { enabled: !!leaderId });

    const { mutate: editProject } = useUpdateProject();
    const { mutate: deleteLineLeader } = useDeleteLineLeader();

    const [leader, setLeader] = useState<LineLeader>(getLeader());

    function getLeader(){
        return line.leaders.find(l => l.id === leaderId)!;
    }

    function onSetFunction(e: SelectChangeEvent<string>){
        setLeader(prev => ({...prev, function: e.target.value}));
    }

    function onChangeLeader(e: SelectChangeEvent<string>){
        const employee = employeeList.find(employee => employee.employeeId === e.target.value);
        if (!employee){ return; }
        setLeader(prev => ({
            ...prev,
            employeeId: employee.employeeId,
            name: employee.nameTitleCase
        }))
    }

    const handleClickCancelDialog = () => {
        setLeaderId("");
        setLeader(getLeader());
    };

    const canEditLeader = useMemo(() => {
        return !!leader.name && !!leader.function && !!leader.employeeId;
    }, [leader]);

    function onEditLeader(){
        if (!canEditLeader){ return; }
        const project = projectList.find(project => project.lines.some(l => l.id === line.id));
        if (!project){ return; }
        const payload: Project = {
            ...project,
            lines: project.lines.map(l => l.id === line.id ? {...l, leaders: l.leaders.map(lead => lead.id === leaderId ? leader : lead) } : l)
        };
        editProject(payload, { onSuccess: handleClickCancelDialog });
    }

    function onDeleteLeader(){
        const project = projectList.find(project => project.lines.some(l => l.id === line.id));
        if (!project){ return; }
        const shouldDelete = window.confirm(`Are you sure that you want to delete the leader?`);
        if (!shouldDelete) { return; }
        const newProjectState: Project = {
            ...project,
            lines: project.lines
                .map(l => l.id === line.id
                    ? {...l, leaders: l.leaders.filter(lead => lead.id !== leaderId) }
                    : l
                )
        };
        deleteLineLeader({ id: leaderId, project: newProjectState }, { onSuccess: handleClickCancelDialog });
    }

    return (
        <Dialog open={!!leaderId} fullWidth>
            <DialogTitle>Edit leader</DialogTitle>

            <DialogContent>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="function">Function<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="function"
                        type="text"
                        value={leader.function}
                        fullWidth
                        onChange={onSetFunction}
                    >
                        <MenuItem value={"Leader"}>Leader</MenuItem>
                        <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                        <MenuItem value={"Manager"}>Manager</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="leaderEmployeeId">Leader<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="leaderEmployeeId"
                        type="text"
                        value={leader.employeeId}
                        renderValue={() => `${leader.name} (${leader.employeeId})`}
                        fullWidth
                        onChange={onChangeLeader}
                    >
                        {employeeList.map((employee: EmployeeDto) => <MenuItem key={employee.employeeId} value={employee.employeeId}>{employee.nameTitleCase} ({employee.employeeId})</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "space-between", mx: 1.5 }}>
                <Button onClick={onDeleteLeader} color="error">Delete</Button>

                <Box>
                    <Button onClick={handleClickCancelDialog}>Cancel</Button>
                    <Button variant="contained" disabled={!canEditLeader} onClick={onEditLeader}>Save</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}