import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchDelete, fetchGet, fetchPost, fetchPut } from 'wcz-layout';
import Department from '../../models/Department';
import HeadcountSummaryModel from '../../models/HeadcountSummaryModel';
import Position from '../../models/Position';
import { apiUrl } from '../../utils/BaseUrl';
import { ProjectSettingsContext } from '../../contexts/ProjectSettingsContext';
import ProjectLine from '../../models/ProjectLine';
import LineStation from '../../models/LineStation';
import Project from '../../models/Project';
import { useGetDepartments } from '../../queries/DepartmentQueries';
import { useGetPsPositions } from '../../queries/PsEmployeeQueries';

interface AddAndEditDialogProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    title: string,
    employee: HeadcountSummaryModel,
    setEmployee: (employee: HeadcountSummaryModel) => void,
    rows: HeadcountSummaryModel[],
    setRows: (rows: HeadcountSummaryModel[]) => void,
}

export default function AddAndEditDialog(props: AddAndEditDialogProps) {
    const { projectList } = useContext(ProjectSettingsContext);

    const { data: departmentList = [] } = useGetDepartments();

    const [positionList, setPositionList] = useState([] as string[]);

    // Dialog: Add new employee - validation variables
    const [fieldDepartmentMissingValue, setFieldDepartmentMissingValue] = useState(false);
    const [fieldDepartmentHelperText, setFieldDepartmentHelperText] = useState("");
    const [fieldFirstNameMissingValue, setFieldFirstNameMissingValue] = useState(false);
    const [fieldFirstNameHelperText, setFieldFirstNameHelperText] = useState("");
    const [fieldLastNameMissingValue, setFieldLastNameMissingValue] = useState(false);
    const [fieldLastNameHelperText, setFieldLastNameHelperText] = useState("");
    const [fieldEmployeeIdMissingValue, setFieldEmployeeIdMissingValue] = useState(false);
    const [fieldEmployeeIdHelperText, setFieldEmployeeIdHelperText] = useState("");
    const [fieldProjectMissingValue, setFieldProjectMissingValue] = useState(false);
    const [fieldProjectHelperText, setFieldProjectHelperText] = useState("");
    const [fieldLineMissingValue, setFieldLineMissingValue] = useState(false);
    const [fieldLineHelperText, setFieldLineHelperText] = useState("");
    const [fieldStationMissingValue, setFieldStationMissingValue] = useState(false);
    const [fieldStationHelperText, setFieldStationHelperText] = useState("");
    const [fieldPositionMissingValue, setFieldPositionMissingValue] = useState(false);
    const [fieldPositionHelperText, setFieldPositionHelperText] = useState("");
    const [fieldWorkStatusMissingValue, setFieldWorkStatusMissingValue] = useState(false);
    const [fieldWorkStatusHelperText, setFieldWorkStatusHelperText] = useState("");
    const [fieldWorkShiftStartMissingValue, setFieldWorkShiftStartMissingValue] = useState(false);
    const [fieldWorkShiftStartHelperText, setFieldWorkShiftStartHelperText] = useState("");
    const [fieldWorkShiftEndMissingValue, setFieldWorkShiftEndMissingValue] = useState(false);
    const [fieldWorkShiftEndHelperText, setFieldWorkShiftEndHelperText] = useState("");

    const canSave = () => {
        let result = true;
        if (!props.employee.departmentId) {
            setFieldDepartmentMissingValue(true);
            setFieldDepartmentHelperText("Field Department is required.");
            result = false;
        }
        if (!props.employee.firstName) {
            setFieldFirstNameMissingValue(true);
            setFieldFirstNameHelperText("Field First Name is required.");
            result = false;
        }
        if (!props.employee.lastName && props.employee.employer !== "SMS-P") {
            setFieldLastNameMissingValue(true);
            setFieldLastNameHelperText("Field Last Name is required.");
            result = false;
        }
        if (!props.employee.employeeId) {
            setFieldEmployeeIdMissingValue(true);
            setFieldEmployeeIdHelperText("Field Employee No. is required.");
            result = false;
        }
        if (!props.employee.project) {
            setFieldProjectMissingValue(true);
            setFieldProjectHelperText("Field Project is required.");
            result = false;
        }
        if (!props.employee.line) {
            setFieldLineMissingValue(true);
            setFieldLineHelperText("Field Line is required.");
            result = false;
        }
        if (!props.employee.station) {
            setFieldStationMissingValue(true);
            setFieldStationHelperText("Field Station is required.");
            result = false;
        }
        if (!props.employee.position) {
            setFieldPositionMissingValue(true);
            setFieldPositionHelperText("Field Job Position (Contract) is required.");
            result = false;
        }
        if (!props.employee.workStatus) {
            setFieldWorkStatusMissingValue(true);
            setFieldWorkStatusHelperText("Field Working Status is required.");
            result = false;
        }
        if (!props.employee.workshiftStart) {
            setFieldWorkShiftStartMissingValue(true);
            setFieldWorkShiftStartHelperText("Field Working Shift - Start is required.");
            result = false;
        }
        if (!props.employee.workshiftEnd) {
            setFieldWorkShiftEndMissingValue(true);
            setFieldWorkShiftEndHelperText("Field Working Shift - End is required.");
            result = false;
        }

        return result;
    }

    const cleanValidation = () => {
        setFieldDepartmentMissingValue(false);
        setFieldDepartmentHelperText("");
        setFieldFirstNameMissingValue(false);
        setFieldFirstNameHelperText("");
        setFieldLastNameMissingValue(false);
        setFieldLastNameHelperText("");
        setFieldEmployeeIdMissingValue(false);
        setFieldEmployeeIdHelperText("");
        setFieldProjectMissingValue(false);
        setFieldProjectHelperText("");
        setFieldLineMissingValue(false);
        setFieldLineHelperText("");
        setFieldStationMissingValue(false);
        setFieldStationHelperText("");
        setFieldPositionMissingValue(false);
        setFieldPositionHelperText("");
        setFieldWorkStatusMissingValue(false);
        setFieldWorkStatusHelperText("");
        setFieldWorkShiftStartMissingValue(false);
        setFieldWorkShiftStartHelperText("");
        setFieldWorkShiftEndMissingValue(false);
        setFieldWorkShiftEndHelperText("");
    }

    useGetPsPositions(projectList, {
        enabled: props.open,
        onSuccess: data => {
            let uniquePositionList = data.map(position => position.position).filter((val, i, arr) => arr.indexOf(val) === i).sort((a, b) => a.localeCompare(b));
            setPositionList(uniquePositionList);
        }
    });

    const handleClickCloseDialog = () => {
        props.setOpen(false);
        cleanValidation();
    };

    const { mutate: postNewEmployee } = useMutation((request: HeadcountSummaryModel) => fetchPost(`${apiUrl}/v1/hsemployee`, request));
    const { mutate: putUpdatedEmployee } = useMutation((request: HeadcountSummaryModel) => fetchPut(`${apiUrl}/v1/hsemployee/${request.id}`, request));
    const { mutate: deleteEmployee } = useMutation((request: HeadcountSummaryModel) => fetchDelete(`${apiUrl}/v1/hsemployee/${request.id}`));

    const handleClickSaveEmployee = () => {
        if (!canSave()) return;

        let newRows = props.rows;

        if (props.employee.isNew) {
            postNewEmployee(props.employee);  
            newRows = [props.employee, ...newRows];
        }
        else {            
            putUpdatedEmployee(props.employee);
            newRows = newRows.map((r) => r.id === props.employee.id ? props.employee : r);
        }

        props.setRows(newRows);
        props.setOpen(false);
    };

    const handleClickDeleteEmployee = () => {
        deleteEmployee(props.employee);

        let newRows = props.rows.filter(r => r.id !== props.employee.id);
        props.setRows(newRows);
        props.setOpen(false);
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="employer">Employer<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        autoFocus
                        margin="dense"
                        id="employer"
                        type="text"
                        value={props.employee.employer}
                        fullWidth
                        onChange={(event) => {
                            if (event.target.value === "SMS-P" && fieldFirstNameMissingValue) {
                                setFieldFirstNameMissingValue(false);
                                setFieldFirstNameHelperText("");
                            }
                            if (event.target.value === "SMS-P" && fieldLastNameMissingValue) {
                                setFieldLastNameMissingValue(false);
                                setFieldLastNameHelperText("");
                            }
                            props.setEmployee({
                                ...props.employee,
                                employer: event.target.value,
                                firstName: event.target.value === "SMS-P" ? "Supporter" : props.employee.firstName !== "Supporter" ? props.employee.firstName : "",
                                lastName: event.target.value === "SMS-P" ? "" : props.employee.lastName !== "" ? props.employee.lastName : "",
                            })
                        }}
                    >
                        <MenuItem value={"SMS-P"}>SMS-P</MenuItem>
                        <MenuItem value={"SMS"}>SMS</MenuItem>
                        <MenuItem value={"Agency"}>Agency</MenuItem>
                        <MenuItem value={"3rd party"}>3rd party</MenuItem>
                        <MenuItem value={"WIH"}>WIH</MenuItem>
                        <MenuItem value={"WSPH"}>WSPH</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="departmentId" sx={{ color: fieldDepartmentMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Department<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="departmentId"
                        type="text"
                        value={props.employee.departmentId}
                        error={fieldDepartmentMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldDepartmentMissingValue && event.target.value !== "") {
                                setFieldDepartmentMissingValue(false);
                                setFieldDepartmentHelperText("");
                            }

                            props.setEmployee({
                                ...props.employee,
                                departmentId: event.target.value,
                                departmentDescription: departmentList.find(d => d.departmentId === event.target.value)?.description ?? "",
                                project: "",
                                line: "",
                                station: ""
                            });
                        }}
                    >
                        {departmentList.map((department: Department) => <MenuItem key={department.departmentId} value={department.departmentId}>{department.departmentId} - {department.description}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldDepartmentHelperText}</FormHelperText>
                </FormControl>
                <TextField
                    margin="dense"
                    id="firstName"
                    label={<React.Fragment>First Name<span style={{ color: "red" }}>*</span></React.Fragment>}
                    type="text"
                    value={props.employee.firstName}
                    error={fieldFirstNameMissingValue}
                    helperText={fieldFirstNameHelperText}
                    disabled={props.employee.employer === "SMS-P"}
                    sx={{ width: "50%", pr: '16px' }}
                    onChange={(event) => {
                        if (fieldFirstNameMissingValue && event.target.value !== "") {
                            setFieldFirstNameMissingValue(false);
                            setFieldFirstNameHelperText("");
                        }
                        props.setEmployee({ ...props.employee, firstName: event.target.value });
                    }}
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="lastName"
                    label={<React.Fragment>Last Name{props.employee.employer !== "SMS-P" && <span style={{ color: "red" }}>*</span>}</React.Fragment>}
                    type="text"
                    value={props.employee.lastName}
                    error={fieldLastNameMissingValue}
                    helperText={fieldLastNameHelperText}
                    disabled={props.employee.employer === "SMS-P"}
                    sx={{ width: "50%" }}
                    onChange={(event) => {
                        if (fieldLastNameMissingValue && event.target.value !== "") {
                            setFieldLastNameMissingValue(false);
                            setFieldLastNameHelperText("");
                        }
                        props.setEmployee({ ...props.employee, lastName: event.target.value });
                    }}
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="employeeId"
                    label={<React.Fragment>Employee No.<span style={{ color: "red" }}>*</span></React.Fragment>}
                    type="text"
                    value={props.employee.employeeId}
                    error={fieldEmployeeIdMissingValue}
                    helperText={fieldEmployeeIdHelperText}
                    fullWidth
                    onChange={(event) => {
                        if (fieldEmployeeIdMissingValue && event.target.value !== "") {
                            setFieldEmployeeIdMissingValue(false);
                            setFieldEmployeeIdHelperText("");
                        }
                        props.setEmployee({ ...props.employee, employeeId: event.target.value });
                    }}
                    variant="standard"
                />
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="project" sx={{ color: fieldProjectMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Project<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="project"
                        type="text"
                        value={props.employee.project}
                        error={fieldProjectMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldProjectMissingValue && event.target.value !== "") {
                                setFieldProjectMissingValue(false);
                                setFieldProjectHelperText("");
                            }
                            let lines = projectList.find(p => p.name === event.target.value && p.departmentId === props.employee.departmentId)?.lines ?? [];
                            if (fieldLineMissingValue && lines.length === 1) {
                                setFieldLineMissingValue(false);
                                setFieldLineHelperText("");
                            }
                            props.setEmployee({
                                ...props.employee,
                                project: event.target.value,
                                line: lines?.length === 1 ? lines[0].name : "",
                                station: ""
                            });
                        }}
                    >
                        {projectList.filter(p => p.departmentId === props.employee.departmentId).map((project: Project) => <MenuItem key={project.id} value={project.name}>{project.name}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldProjectHelperText}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="line" sx={{ color: fieldLineMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Line<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="line"
                        type="text"
                        value={props.employee.line}
                        error={fieldLineMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldLineMissingValue && event.target.value !== "") {
                                setFieldLineMissingValue(false);
                                setFieldLineHelperText("");
                            }
                            props.setEmployee({ ...props.employee, line: event.target.value });
                        }}
                    >
                        {projectList.find(p => p.name === props.employee.project && p.departmentId === props.employee.departmentId)?.lines.map((line: ProjectLine) => <MenuItem key={line.id} value={line.name}>{line.name}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldLineHelperText}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="station" sx={{ color: fieldStationMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Station<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="station"
                        type="text"
                        value={props.employee.station}
                        error={fieldStationMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldStationMissingValue && event.target.value !== "") {
                                setFieldStationMissingValue(false);
                                setFieldStationHelperText("");
                            }
                            props.setEmployee({ ...props.employee, station: event.target.value });
                        }}
                    >
                        {projectList.find(p => p.name === props.employee.project && p.departmentId === props.employee.departmentId)?.lines.find(line => line.name === props.employee.line)?.stations.map((station: LineStation) => <MenuItem key={station.id} value={station.name}>{station.name}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldStationHelperText}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="position" sx={{ color: fieldPositionMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Job Position (Contract)<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="position"
                        type="text"
                        value={props.employee.position}
                        error={fieldPositionMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldPositionMissingValue && event.target.value !== "") {
                                setFieldPositionMissingValue(false);
                                setFieldPositionHelperText("");
                            }
                            props.setEmployee({ ...props.employee, position: event.target.value });
                        }}
                    >
                        {positionList.map((position: string) => <MenuItem key={position} value={position}>{position}</MenuItem>)}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldPositionHelperText}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="workStatus" sx={{ color: fieldWorkStatusMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Working Status<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="workStatus"
                        type="text"
                        value={props.employee.workStatus}
                        error={fieldWorkStatusMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldWorkStatusMissingValue && event.target.value !== "") {
                                setFieldWorkStatusMissingValue(false);
                                setFieldWorkStatusHelperText("");
                            }
                            props.setEmployee({ ...props.employee, workStatus: event.target.value });
                        }}
                    >
                        <MenuItem value={"Training"}>Training</MenuItem>
                        <MenuItem value={"Qualify"}>Qualify</MenuItem>                        
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldWorkStatusHelperText}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="contractStatus">Contract Status<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="contractStatus"
                        type="text"
                        value={props.employee.contractStatus}
                        fullWidth
                        onChange={(event) => props.setEmployee({ ...props.employee, contractStatus: event.target.value })}
                    >
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"L.O.A"}>L.O.A</MenuItem>
                        <MenuItem value={"Terminated"}>Terminated</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="workshiftStart" sx={{ color: fieldWorkShiftStartMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Working Shift - Start<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="workshiftStart"
                        type="text"
                        value={props.employee.workshiftStart}
                        error={fieldWorkShiftStartMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldWorkShiftStartMissingValue && event.target.value !== "") {
                                setFieldWorkShiftStartMissingValue(false);
                                setFieldWorkShiftStartHelperText("");
                                setFieldWorkShiftEndMissingValue(false);
                                setFieldWorkShiftEndHelperText("");
                            }
                            props.setEmployee({
                                ...props.employee,
                                workshiftStart: event.target.value,
                                workshiftEnd: moment(event.target.value, "HH:mm").add(525, 'minutes').format("HH:mm")
                            });
                        }}
                    >
                        <MenuItem value={"6:00"}>6:00</MenuItem>
                        <MenuItem value={"6:15"}>6:15</MenuItem>
                        <MenuItem value={"6:30"}>6:30</MenuItem>
                        <MenuItem value={"6:45"}>6:45</MenuItem>
                        <MenuItem value={"7:00"}>7:00</MenuItem>
                        <MenuItem value={"7:15"}>7:15</MenuItem>
                        <MenuItem value={"7:30"}>7:30</MenuItem>
                        <MenuItem value={"7:45"}>7:45</MenuItem>
                        <MenuItem value={"8:00"}>8:00</MenuItem>
                        <MenuItem value={"8:15"}>8:15</MenuItem>
                        <MenuItem value={"8:30"}>8:30</MenuItem>
                        <MenuItem value={"8:45"}>8:45</MenuItem>
                        <MenuItem value={"9:00"}>9:00</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldWorkShiftStartHelperText}</FormHelperText>
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="workshiftEnd" sx={{ color: fieldWorkShiftEndMissingValue ? "#d32f2f" : "rgba(0, 0, 0, 0.6)" }}>Working Shift - End<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="workshiftEnd"
                        type="text"
                        value={props.employee.workshiftEnd}
                        error={fieldWorkShiftEndMissingValue}
                        fullWidth
                        onChange={(event) => {
                            if (fieldWorkShiftEndMissingValue && event.target.value !== "") {
                                setFieldWorkShiftEndMissingValue(false);
                                setFieldWorkShiftEndHelperText("");
                            }
                            props.setEmployee({ ...props.employee, workshiftEnd: event.target.value });
                        }}
                    >
                        <MenuItem value={"14:00"}>14:00</MenuItem>
                        <MenuItem value={"14:15"}>14:15</MenuItem>
                        <MenuItem value={"14:30"}>14:30</MenuItem>
                        <MenuItem value={"14:45"}>14:45</MenuItem>
                        <MenuItem value={"15:00"}>15:00</MenuItem>
                        <MenuItem value={"15:15"}>15:15</MenuItem>
                        <MenuItem value={"15:30"}>15:30</MenuItem>
                        <MenuItem value={"15:45"}>15:45</MenuItem>
                        <MenuItem value={"16:00"}>16:00</MenuItem>
                        <MenuItem value={"16:15"}>16:15</MenuItem>
                        <MenuItem value={"16:30"}>16:30</MenuItem>
                        <MenuItem value={"16:45"}>16:45</MenuItem>
                        <MenuItem value={"17:00"}>17:00</MenuItem>
                        <MenuItem value={"17:15"}>17:15</MenuItem>
                        <MenuItem value={"17:30"}>17:30</MenuItem>
                        <MenuItem value={"17:45"}>17:45</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>{fieldWorkShiftEndHelperText}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                {
                    !props.employee.isNew &&
                    <Button color="error" sx={{ marginRight: "auto", marginLeft: "10px" }} onClick={handleClickDeleteEmployee}>Delete</Button>
                }
                <Button onClick={handleClickCloseDialog}>Cancel</Button>
                <Button onClick={handleClickSaveEmployee}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}