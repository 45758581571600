import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LayoutContext, fetchDelete, fetchGet, fetchPost, fetchPut } from "wcz-layout";
import { apiUrl } from "../utils/BaseUrl";
import { useContext } from "react";
import Project from "../models/Project";

const queryKey = "project";

export function useGetProjects<TQueryFnData = Project[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey], ({ signal }) => fetchGet(`${apiUrl}/v1/${queryKey}`, signal), options);
}

export function useGetProject<TQueryFnData = Project, TError = string, TData = TQueryFnData>(id: string | undefined, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([queryKey, id], ({ signal }) => fetchGet(`${apiUrl}/v1/${queryKey}/${id}`, signal), options);
}

interface UseCreateOptions {
    onSuccess?: (data: Project) => void,
    onError?: (message: string) => void,
}

export function useCreateProject(options?: UseCreateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Project) => fetchPost(`${apiUrl}/v1/${queryKey}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousDataList = queryClient.getQueryData<Project[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], [model, ...previousDataList]);

            return { previousDataList };
        },
        onError: (err: string, model, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: (model, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], [model, ...context.previousDataList ?? []]);
            }
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
};

interface UseUpdateOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateProject(options?: UseUpdateOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: Project) => fetchPut(`${apiUrl}/v1/${queryKey}/${model.id}`, model), {
        onMutate: async (model) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });
            await queryClient.cancelQueries({ queryKey: [queryKey, model.id] });

            const previousDataList = queryClient.getQueryData<Project[]>([queryKey]);
            if (previousDataList)
                queryClient.setQueryData([queryKey], previousDataList.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData<Project>([queryKey, model.id]);
            if (previousData)
                queryClient.setQueryData([queryKey, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (err: string, variables, context) => {
            if (context) {
                queryClient.setQueryData([queryKey], context.previousDataList);
                queryClient.setQueryData([queryKey, variables.id], context.previousData);
            }
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteProject(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation((id: string) => fetchDelete(`${apiUrl}/v1/${queryKey}/${id}`), {
        onMutate: async (id) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Project[]>([queryKey]);

            if (previousData) {
                queryClient.setQueryData([queryKey], previousData.filter(prev => prev.id !== id));

                return { previousData };
            }
        },
        onError: (err: string, id, context) => {
            if (context)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};

interface UseDeleteOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useDeleteLineLeader(options?: UseDeleteOptions) {
    const queryClient = useQueryClient();
    const { snackbar } = useContext(LayoutContext);

    return useMutation(({ id }: { id: string, project: Project }) => fetchDelete(`${apiUrl}/v1/${queryKey}/Leader/${id}`), {
        onMutate: async ({ project }: { id: string, project: Project }) => {
            await queryClient.cancelQueries({ queryKey: [queryKey] });

            const previousData = queryClient.getQueryData<Project[]>([queryKey]);

            if (previousData) {
                const newState = previousData.map(el => el.id === project.id ? project : el);
                queryClient.setQueryData([queryKey], newState);
            }
            return { previousData };
        },
        onError: (err: string, id, context) => {
            if (context?.previousData)
                queryClient.setQueryData([queryKey], context.previousData);
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
};