import { Approval, Close, Done, QuestionMark } from "@mui/icons-material";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { SetStateAction, useContext, useState } from "react";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import ApplicationData from "../../models/ApplicationData";
import { ApprovalStatus } from "../../models/ApprovalStatus";
import DailyWorkloadApplication from "../../models/DailyWorkloadApplication";
import Head from "../../models/Head";
import { apiUrl } from "../../utils/BaseUrl";

interface DailyWorkloadApplicationDialogProps {
    dailyWorkloadApplicationId: string,
    setDailyWorkloadApplicationId: (id: string) => void,
    setDailyWorkloadApplications: (applicationArray: SetStateAction<DailyWorkloadApplication[]>) => void,
    headsOfEmployee: Head[],
    lockedForEditing: boolean,
    setDailyWorkloadApplicationIdToEdit: (id: string) => void,
}

export default function DailyWorkloadApplicationDialog(props: DailyWorkloadApplicationDialogProps) {
    const { user } = useContext(LayoutContext);
    const [dailyWorkloadApplication, setDailyWorkloadApplication] = useState<DailyWorkloadApplication>({} as DailyWorkloadApplication);

    // Dialog: validation variables
    const [fieldApproverCommentHasError, setFieldApproverCommentHasError] = useState(false);

    useQuery<DailyWorkloadApplication>(["dailyWorkloadApp", props.dailyWorkloadApplicationId], ({ signal }) => fetchGet(`${apiUrl}/v1/dailyworkloadapplication/${props.dailyWorkloadApplicationId}`, signal), {
        enabled: !!props.dailyWorkloadApplicationId,
        onSuccess: data => setDailyWorkloadApplication(data)
    });

    const handleClickCancelDialog = () => {
        props.setDailyWorkloadApplicationId("");
        setFieldApproverCommentHasError(false);
    };

    const handleClickEdit = () => {
        props.setDailyWorkloadApplicationId("");
        props.setDailyWorkloadApplicationIdToEdit(dailyWorkloadApplication.id!);
    };

    const { mutate: putApproveDailyWorkloadApplication } = useMutation((request: any) => fetchPut(`${apiUrl}/v1/dailyworkloadapplication/approve/${request.id}`, request), {
        onSuccess: () => {
            props.setDailyWorkloadApplications(prevState => {
                let dwApplicationToUpdate = prevState.find(app => app.id === dailyWorkloadApplication.id);
                if (dwApplicationToUpdate) {
                    dwApplicationToUpdate.status = ApprovalStatus.Approved;
                    dwApplicationToUpdate.approverComment = dailyWorkloadApplication.approverComment;

                    let newDailyWorkloadApplications = [...prevState.filter(app => app.id !== dailyWorkloadApplication.id), dwApplicationToUpdate];
                    return newDailyWorkloadApplications;
                }

                return prevState;
            });
        }
    });

    const handleClickApprove = () => {
        let request = {
            id: dailyWorkloadApplication.id,
            approverComment: dailyWorkloadApplication.approverComment
        };

        putApproveDailyWorkloadApplication(request);
        props.setDailyWorkloadApplicationId("");
    };

    const { mutate: putRejectDailyWorkloadApplication } = useMutation((request: any) => fetchPut(`${apiUrl}/v1/dailyworkloadapplication/reject/${request.id}`, request), {
        onSuccess: () => {
            props.setDailyWorkloadApplications(prevState => {
                let dwApplicationToUpdate = prevState.find(app => app.id === dailyWorkloadApplication.id);
                if (dwApplicationToUpdate) {
                    dwApplicationToUpdate.status = ApprovalStatus.Rejected;
                    dwApplicationToUpdate.approverComment = dailyWorkloadApplication.approverComment;

                    let newDailyWorkloadApplications = [...prevState.filter(app => app.id !== dailyWorkloadApplication.id), dwApplicationToUpdate];
                    return newDailyWorkloadApplications;
                }

                return prevState;
            });
        }
    });

    const handleClickReject = () => {
        if (!dailyWorkloadApplication.approverComment) {
            setFieldApproverCommentHasError(true);
            return;
        }

        let request = {
            id: dailyWorkloadApplication.id,
            approverComment: dailyWorkloadApplication.approverComment
        };

        putRejectDailyWorkloadApplication(request);
        props.setDailyWorkloadApplicationId("");
    };

    const { mutate: putWithdrawDailyWorkloadApplication } = useMutation((request: any) => fetchPut(`${apiUrl}/v1/dailyworkloadapplication/withdraw/${request.id}`, request), {
        onSuccess: () => {
            props.setDailyWorkloadApplications(prevState => {
                // const newState = [...prevState];
                // const dwApplicationToUpdate = newState.find(app => app.id === dailyWorkloadApplication.id);
                // if (dwApplicationToUpdate) { dwApplicationToUpdate.status = ApprovalStatus.Withdrawn; }
                // return newState;

                let dwApplicationToUpdate = prevState.find(app => app.id === dailyWorkloadApplication.id);
                if (dwApplicationToUpdate) {
                    dwApplicationToUpdate.status = ApprovalStatus.Withdrawn;

                    let newDailyWorkloadApplications = [...prevState.filter(app => app.id !== dailyWorkloadApplication.id), dwApplicationToUpdate];
                    return newDailyWorkloadApplications;
                }

                return prevState;
            });
        }
    });

    const handleClickWithdraw = () => {
        putWithdrawDailyWorkloadApplication({ id: dailyWorkloadApplication.id });
        props.setDailyWorkloadApplicationId("");
    };

    return (
        <Dialog open={!!props.dailyWorkloadApplicationId} maxWidth={"xl"}>
            <DialogTitle>Daily Workload Application Details:</DialogTitle>

            <DialogContent>                
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={1}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Status:</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        {dailyWorkloadApplication?.status === ApprovalStatus.Approved && <Chip size="small" label={dailyWorkloadApplication.status} sx={{ bgcolor: "#00AC81", color: "white" }} icon={<Done sx={{ color: "white!important" }} />} />}
                        {dailyWorkloadApplication?.status === ApprovalStatus.Rejected && <Chip size="small" label={dailyWorkloadApplication.status} sx={{ bgcolor: "#CC3259", color: "white" }} icon={<Close sx={{ color: "white!important" }} />} />}
                        {dailyWorkloadApplication?.status === ApprovalStatus.WaitingForApproval && <Chip size="small" label={dailyWorkloadApplication.status} sx={{ bgcolor: "orange", color: "white" }} icon={<Approval sx={{ color: "white!important" }} />} />}
                        {dailyWorkloadApplication?.status === ApprovalStatus.Cancelled && <Chip size="small" label={dailyWorkloadApplication.status} sx={{ bgcolor: "#CC3259", color: "white" }} icon={<Close sx={{ color: "white!important" }} />} />}
                        {dailyWorkloadApplication?.status === ApprovalStatus.Withdrawn && <Chip size="small" label={dailyWorkloadApplication.status} sx={{ bgcolor: "#CC3259", color: "white" }} icon={<Close sx={{ color: "white!important" }} />} />}
                        {dailyWorkloadApplication?.status === ApprovalStatus.NoStatus && <Chip size="small" label={dailyWorkloadApplication.status} icon={<QuestionMark />} />}
                    </Grid>
                    <Grid item xs={6} lg={1}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Comment:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            margin="dense"
                            sx={{ mt: '0px', mb: '4px', input: { color: 'black', WebkitTextFillColor: 'inherit!important' } }}
                            fullWidth
                            id="comment"
                            type="text"
                            variant="outlined"
                            size="small"
                            placeholder="Approver's comment (*required when reject)"
                            error={fieldApproverCommentHasError}
                            value={dailyWorkloadApplication?.approverComment ?? ""}
                            disabled={dailyWorkloadApplication?.status !== ApprovalStatus.WaitingForApproval}
                            onChange={(event) => {
                                if (fieldApproverCommentHasError && event.target.value !== "") {
                                    setFieldApproverCommentHasError(false);
                                }
                                setDailyWorkloadApplication({ ...dailyWorkloadApplication, approverComment: event.target.value });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={1}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Approver:</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        {dailyWorkloadApplication?.approverName}
                    </Grid>                    
                </Grid>
            </DialogContent>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={1}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Applicant:</Typography>
                    </Grid>
                    <Grid item xs={6} lg={9}>
                        {dailyWorkloadApplication?.employeeName} ({dailyWorkloadApplication?.employeeId})
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={1}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Date:</Typography>
                    </Grid>
                    <Grid item xs={6} lg={9}>
                        {moment(dailyWorkloadApplication?.day).format("YYYY-MM-DD dddd")}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogContent sx={{ minWidth: "1100px" }}>
                {
                    !dailyWorkloadApplication.isGymVisit &&
                    <Grid container spacing={2} sx={{ borderBottom: "3px solid #005363", mb: "10px" }}>
                        <Grid item sx={{ minWidth: "170px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Department Code</Typography></Grid>
                        <Grid item sx={{ minWidth: "220px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Project</Typography></Grid>
                        <Grid item sx={{ minWidth: "100px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Line</Typography></Grid>
                        <Grid item sx={{ minWidth: "200px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Station</Typography></Grid>
                        <Grid item sx={{ minWidth: "120px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Start</Typography></Grid>
                        <Grid item sx={{ minWidth: "120px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>End</Typography></Grid>
                        <Grid item sx={{ minWidth: "120px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Worked</Typography></Grid>
                        <Grid item sx={{ minWidth: "120px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Overtime</Typography></Grid>
                        <Grid item sx={{ minWidth: "120px" }}><Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Breaktime</Typography></Grid>
                    </Grid>
                }
                {
                    dailyWorkloadApplication?.applicationData?.map((appDataItem: ApplicationData) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item sx={{ minWidth: "170px" }}>
                                    {
                                        appDataItem.oldWorkloadDepartmentId !== appDataItem.newWorkloadDepartmentId ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldWorkloadDepartmentId ?? "n/a"} &rarr; {appDataItem.newWorkloadDepartmentId}
                                            </Typography>
                                            : <span>{appDataItem.newWorkloadDepartmentId}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "220px" }}>
                                    {
                                        appDataItem.oldProject !== appDataItem.newProject ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldProject ?? "n/a"} &rarr; {appDataItem.newProject}
                                            </Typography>
                                            : <span>{appDataItem.newProject}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "100px" }}>
                                    {
                                        appDataItem.oldLine !== appDataItem.newLine ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldLine ?? "n/a"} &rarr; {appDataItem.newLine}
                                            </Typography>
                                            : <span>{appDataItem.newLine}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "200px" }}>
                                    {
                                        appDataItem.oldStation !== appDataItem.newStation ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldStation ?? "n/a"} &rarr; {appDataItem.newStation}
                                            </Typography>
                                            : <span>{appDataItem.newStation}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "120px" }}>
                                    {
                                        appDataItem.oldStart !== appDataItem.newStart ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldStart ?? "n/a"} &rarr; {appDataItem.newStart}
                                            </Typography>
                                            : <span>{appDataItem.newStart}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "120px" }}>
                                    {
                                        appDataItem.oldEnd !== appDataItem.newEnd ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldEnd ?? "n/a"} &rarr; {appDataItem.newEnd}
                                            </Typography>
                                            : <span>{appDataItem.newEnd}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "120px" }}>
                                    {
                                        appDataItem.oldWorked !== appDataItem.newWorked ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldWorked ?? "n/a"} &rarr; {appDataItem.newWorked}
                                            </Typography>
                                            : <span>{appDataItem.newWorked}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "120px" }}>
                                    {
                                        appDataItem.oldOvertime !== appDataItem.newOvertime ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldOvertime ?? "n/a"} &rarr; {appDataItem.newOvertime}
                                            </Typography>
                                            : <span>{appDataItem.newOvertime}</span>
                                    }
                                </Grid>
                                <Grid item sx={{ minWidth: "120px" }}>
                                    {
                                        appDataItem.oldBreaktime !== appDataItem.newBreaktime ?
                                            <Typography component="span" sx={{ color: 'orange' }}>
                                                {appDataItem.oldBreaktime ?? "n/a"} &rarr; {appDataItem.newBreaktime}
                                            </Typography>
                                            : <span>{appDataItem.newBreaktime}</span>
                                    }
                                </Grid>
                            </Grid>  
                        );
                    })
                }
                <Grid container sx={{ mt: "40px" }}>
                    <Grid item xs={12} lg={8}>
                        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 600 }}>Reason for change</Typography>
                        <TextField
                            id="reason"
                            fullWidth
                            disabled
                            sx={{ textarea: { color: 'black', WebkitTextFillColor: 'inherit!important' } }}
                            type="text"
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={4}
                            value={dailyWorkloadApplication?.reason}                            
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Typography component="div" variant="caption" sx={{ marginRight: "auto", marginLeft: "15px" }}>Created on {dailyWorkloadApplication.created} by {dailyWorkloadApplication.createdBy}</Typography>
                {
                    !props.lockedForEditing
                    && (dailyWorkloadApplication.status === ApprovalStatus.Rejected || dailyWorkloadApplication.status === ApprovalStatus.Withdrawn)
                    && dailyWorkloadApplication.createdBy?.includes(user.id)
                    && <Button color="info" onClick={handleClickEdit}>Edit</Button>
                }
                {
                    !props.lockedForEditing
                    && dailyWorkloadApplication.status === ApprovalStatus.WaitingForApproval
                    && dailyWorkloadApplication.createdBy?.includes(user.id)
                    && <Button color="error" onClick={handleClickWithdraw}>Withdraw application</Button>
                }
                <Button onClick={handleClickCancelDialog}>Close dialog</Button>
                {
                    !props.lockedForEditing
                    && dailyWorkloadApplication?.status === ApprovalStatus.WaitingForApproval
                    && (props.headsOfEmployee.some(head => head.employeeId === user.id) || dailyWorkloadApplication.approverEmployeeId === user.id || user.id === "C1107048")
                    &&
                    <>
                        <Button variant="contained" onClick={handleClickApprove}>Approve</Button>
                        <Button variant="contained" sx={{ bgcolor: 'darkred' }} onClick={handleClickReject}>Reject</Button>                        
                    </>
                }
            </DialogActions>
        </Dialog>  
    );
}